import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueTimeago from 'vue-timeago'

// import router from './router' // The one that works
import router from './router/customer'

import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  /* locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  } */
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// multiple subdomain additions
/* const host = window.location.host
const parts = host.split('.')
const domainLength = 3
// route1.example.com => domain length = 3
//console.log(host)
const router = () => {
  let routes
  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    routes = partner
  } else if (parts[0] === 'customer') {
    routes = customer
  } else {
    // If you want to do something else just comment the line below
    routes = partner
  }
  return routes
} */

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
